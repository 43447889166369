import React from 'react';
// TODO: Migrate to `react-dom/client > hydrateRoot` and fix hydration issues
// https://buildkite.com/seek/ie-job-ad-preview-ui/builds/2784#018a4368-c52a-4fa7-a2fc-8bb9dc380635/320-452
// eslint-disable-next-line react/no-deprecated
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './components/App';
import type { Environment } from './config';
import { UIMode } from './types/UIMode';

declare global {
  // Client Context
  interface Window {
    __CLIENT_CONTEXT: {
      jobAdPreview: any;
      hasError: any;
      uiMode: any;
      environment: Environment;
      language: any;
      locale: any;
    };
    __uiMode: any;
  }
}

const { jobAdPreview, hasError, uiMode, environment, language, locale } =
  window.__CLIENT_CONTEXT;
const clientDetectUIMode = window.__uiMode;
const clientUIMode = uiMode ?? clientDetectUIMode;

hydrate(
  <BrowserRouter>
    <App
      jobAdPreview={jobAdPreview}
      uiMode={UIMode.guard(clientUIMode) ? clientUIMode : undefined}
      hasError={hasError}
      environment={environment}
      language={language}
      locale={locale}
    />
  </BrowserRouter>,
  document.getElementById('app'),
);
